import React, { useEffect, useState } from 'react';
import ScratchCard from './ScratchCard/scratchCardGame';
import Background from './ScratchCard/scratchCardBG';
import Modal from './Modal/Modal';
import GlobalStyle from './Fonts/GlobalStyle';
import styled, { keyframes } from 'styled-components';
import Headline from './Headline/Headline';
import Counter from './counter/counter';
import { useNavigate } from 'react-router-dom';

const Main = styled.div`
  position: relative;
`
const Scratch = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const CardDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  margin: auto;
  width: 100%;
  background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6ef0d4f4-b087-485f-c951-55ff7a1fc200/public);
  background-size: cover;

  @media screen and (min-width:768px){
    width: 414px;
    display: block;
    margin: auto;
  }
  
`

const ScratchCardDiv = styled.div`
  margin-top: 140px;
`

const BackgroundDiv = styled.div`
display: flex;
justify-content: center;
`
const ButtonDiv = styled.div`
background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/d2b0899e-48ac-4c75-be8a-4445d54adf00/public);
background-size: 100% 100%;
display: block;
margin:   15px auto 0px auto;
z-index: 10000;
width: 100;
height: 120px;

  button{
    background: none;
    border: none;
    display: block;
    margin: auto;

      img{
        width: 280px;
        display: block;
        margin: auto;
      }
  }

  @media screen and (min-width: 768px){
    width: 400px;

  }

`

const scratchAnimation = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-55deg); }
  100% { transform: rotate(0deg); }
`;


const Btn = styled.div`
  background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/da5ac9a3-4e44-4e00-b113-753020d06100/public);
  width: 310px;
  height: 50px;
  background-size: 100% 100%;
  margin: 5px 0px;
  position: relative;
  animation:pulse 1s infinite;
  
  @keyframes pulse {
    0% {    transform: scaleX(1)  }
    50% {    transform: scale3d(1.05, 1.05, 1.05)  }
    to {    transform: scaleX(1)  }
  

`


// Use the defined keyframes in your styled component
const Hand = styled.img`
  width: 20px !important;
  position: absolute;
  right: 45px;
  top: 15px;
  animation: ${scratchAnimation} 2s infinite;
`;

const App: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [ ,setScratchedPercent] = useState(0);
  const [animate, setAnimate] = useState(false); // State to control animation
  const [cover] = useState(true);
  const [btn, setBtn] = useState(true);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = queryParams.get('_token');
  const [token, setToken] = useState<string | null>(tokenFromUrl);

  useEffect(() => {
    // Ensure to declare queryParams within useEffect to capture its scope
    const queryParams = new URLSearchParams(window.location.search);

    if (!token) {
      const newToken = '';
      setToken(newToken);

      queryParams.set('_token', newToken);
      navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [token, navigate]); // Include token and navigate in the dependency array
  

  const handleScratch = (scratched: boolean) => {
    if (scratched) {
      setTimeout(()=>{
        setShowModal(true);
        setBtn(false);
      }, 2000)
      setAnimate(true);

    }
  };
  

  const handleScratchedPercentChange = (percent: number) => {
    setScratchedPercent(percent);
    if (percent > 70) {
      handleScratch(true);
    }
  };


  return (
    <Main>
      <Counter />
      <GlobalStyle />
      <Headline />
      <Scratch>
        <BackgroundDiv>
          <Background onScratch={handleScratch}
            animate={animate}
          />
        </BackgroundDiv>
        <CardDiv>
          <ScratchCardDiv>
            {cover && (

              <ScratchCard
                width={350}
                height={350}
                image="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/66f8bf68-c14c-41f3-04f2-baf85e2e3300/public"
                brushSize={30}
                onScratch={handleScratch}
                onScratchedPercentChange={handleScratchedPercentChange}
              />
            )}
          </ScratchCardDiv>
        </CardDiv>
      </Scratch>
      <ButtonDiv>
        {btn && (
          <button>
            <Btn>
              <Hand src='https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6fa11bd7-f975-43b2-5bdb-f78e986d9500/public' />
            </Btn>
            
          </button>
        )}
      </ButtonDiv>
      {showModal && <Modal token={token} />}
    </Main>
  );
};
export default App;
